import { Link } from "./link";
import clsx from "clsx";

export function BreadCrumbs({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<nav
			aria-label="Breadcrumb"
			className={`mb-layout2 lg:mb-layout4 ${className || ""}`}
		>
			<ol>{children}</ol>
		</nav>
	);
}

export function Crumb({
	to,
	children,
	isCurrentPage = false,
}: {
	to?: string;
	children: React.ReactNode;
	isCurrentPage?: boolean;
}) {
	return (
		<li
			className={clsx(
				"ml-3 inline text-sm before:mr-3 before:font-medium before:text-grey-40 before:content-['>'] first-of-type:ml-0 first-of-type:before:content-none",
				{
					"font-medium text-grey-60 dark:text-grey-40": isCurrentPage,
				}
			)}
			aria-current={isCurrentPage ? "page" : undefined}
		>
			{!isCurrentPage && to ? (
				<Link
					className="hover-underline-expand font-medium dark:text-grey-5"
					to={to}
					trackingPosition="breadcrumb"
				>
					<span data-label="link-title">{children}</span>
				</Link>
			) : (
				<>{children}</>
			)}
		</li>
	);
}
